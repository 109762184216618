import axios from 'axios'

export default {

  async discover({pageNumber, preferences}) {

    let apiKey = 'api_key=8b7fb4aa32636d1c57093351f7cdb242'
    let sortBy = '&sort_by=popularity.desc'
    let region = '&region=FR'
    let language = '&language=fr-FR'
    let watchRegion = '&watch_region=FR'
    let withWatchProviders = preferences.providers ? `&with_watch_providers=${preferences.providers.join('|')}` : null
    let with_genres = preferences.genres ? `&with_genres=${preferences.genres.join('|')}` : null
    let page = '&page='+ pageNumber
    let release_date_gte = release_date_gte ? `&release_date.gte=${preferences.dateStart}` : null
    let release_date_lte = release_date_lte ? `&release_date.lte=${preferences.dateEnd}` : null
    // let with_watch_monetization_types = `with_watch_monetization_types=flatrate,free`

    return axios.get(`https://api.themoviedb.org/3/discover/movie?${apiKey}${sortBy}${region}${language}${watchRegion}${withWatchProviders}${page}${with_genres}${release_date_gte}${release_date_lte}`)
  },
  
  async getById(id) {
    return axios.get('https://api.themoviedb.org/3/discover/movie?api_key=8b7fb4aa32636d1c57093351f7cdb242&region=FR&language=fr-FR&movie_id='+ id)
  }
}