import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import socketio from 'socket.io-client';
import VueSocketIO from 'vue-socket.io';

Vue.config.productionTip = false

const SocketInstance = socketio.connect('https://server.movie-matcher.net');
// const SocketInstance = socketio.connect('http://localhost:3000');

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketInstance
}));

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
