import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import imdb from './modules/imdb'
import providers from './modules/providers'
import movies from './modules/movies'
import party from './modules/party'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    imdb,
    providers,
    movies,
    party
  }
})
