<template>
  <v-layout justify-center align-center column pa-5>
    <v-btn fab dark absolute fixed bottom right class="mb-10" @click="onProvidersReady()"><v-icon>{{ fabIcon }}</v-icon></v-btn>
    Selection des plateformes:
    <v-text-field v-model="providersFilter" prepend-icon="mdi-magnify"></v-text-field>
    <v-list dense flat>
      <v-list-item-group v-model="providersVoted" multiple>
        <v-list-item v-for="provider in filteredProviders" :key="provider.provider_id" @click="toggleProvider(provider.provider_id)">
          <v-list-item-avatar style="overflow: visible;">
            <v-chip>{{ getVoteCount(provider.provider_id) }}</v-chip>
            <v-badge v-show="getVotedByUser(provider.provider_id)" bordered bottom dot offset-x="8" offset-y="-5" style="z-index: 40;" ></v-badge>
          </v-list-item-avatar>
          <v-list-item-content>{{ provider.provider_name }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-layout>
</template>

<script>
  export default {
    name: 'ProviderSelection',

    data: () => ({
      providersVoted: [],
      providersFilter: ""
    }),

    props: {
      providers: { type: Array, default: () => []},
      partyProviders: { type: Array, default: () => []},
      users: { type: Array, default: () => []},
      userId: { type: String, required: true}
    },

    computed: {

      fabIcon(){
        return this.users.find(u => u.id == this.userId).providersReady ? "mdi-close" : "mdi-check"
      },

      filteredProviders() {

        const filter = this.providersFilter.toUpperCase()

        return this.providers.filter( p => {
          const name = p.provider_name.toUpperCase()
          return name.includes(filter) || filter.includes(name)
        })
      },
    },

    methods: {

      getVoteCount(id){

        if(this.partyProviders.find(p => p.id == id)){
          return this.partyProviders.find(p => p.id == id).votes.length
        }
        else{
          return 0
        }
      },

      getVotedByUser(id){

        if(this.partyProviders.find(p => p.id == id)){
          return this.partyProviders.find(p => p.id == id).votes.includes(this.userId)
        }
        else{
          return false
        }
      },

      toggleProvider(id){

        if(this.partyProviders.find(p => p.id == id) == null || !this.partyProviders.find(p => p.id == id).votes.includes(this.userId)){
            this.$emit('onVoteProvider', id)
        }
        else{
          this.$emit('onUnvoteProvider', id)
        }
      },

      onProvidersReady(){
        this.$emit("onProvidersReady")
      }
    }
  }
</script>