import Vue from 'vue' 
import VueRouter from "vue-router"

import Home from '@/views/Home.vue'
import Party from '@/views/Party.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/:partyCode',
      name: 'Party',
      component: Party
    }
  ]
})

export default router
