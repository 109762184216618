<template>
  <v-layout justify-center align-center column pa-5>
    <v-btn 
      v-show="users.length > 1"
      fab 
      dark 
      absolute 
      fixed 
      bottom 
      right 
      class="mb-10"
      @click="onReady()"
      >
      <v-icon>{{ fabIcon }}</v-icon>
    </v-btn>
    <v-text-field :value="partyCode" readonly outlined label="Code à partager">
      <template v-slot:append>
        <v-btn icon large @click="copyPartyCode"><v-icon>mdi-clipboard-text</v-icon></v-btn>
      </template>
    </v-text-field>
    <p>
      Utilisateurs connectés:
    </p>
    <v-list dense>
      <v-list-item-group>
        <v-list-item v-for="user in users" :key="user.id">
          <v-list-item-icon>
            <v-icon v-if="user.ready">mdi-check</v-icon>
            <v-icon v-else>mdi-help</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ user.name }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div class="loader ma-8"></div>
    <p>
      En attente des autres participants !
    </p>
    <p v-if="users.length > 1">
      Prets {{ users.filter(u => u.ready).length }} / {{ users.length}}
    </p>

    <v-snackbar v-model="snackbar" :timeout="3000" top>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Ok
        </v-btn>
      </template>

    </v-snackbar>

  </v-layout>
</template>

<script>
  export default {
    name: 'WaitingRoom',

    props: {
      users: { type: Array, default: () => []},
      userId: { type: String, required: true},
      partyCode: { type: String, required: true}
    },

    data: () => ({
      snackbar: false,
      snackbarText: ""
    }),

    computed: {

      fabIcon(){
        return this.users.find(u => u.id == this.userId) ? this.users.find(u => u.id == this.userId).ready ? "mdi-close" : "mdi-check" : "mdi-check"
      },
    },

    methods: {
      onReady(){
        this.$emit("onReady")
      },

      copyPartyCode() {

        this.copyToClipboard(this.partyCode).then(() => {
          this.snackbarText = `Code copié dans le presse papier`
          this.snackbar = true
        }).catch(() => {
          this.snackbarText = `Erreur lors de la copie du code.`
          this.snackbar = true
        });
      },

      copyToClipboard(textToCopy) {

        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
      }
    }
  }
</script>