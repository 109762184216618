const state = {
  code: null,
  users: [],
  likes: [],
  dislikes: [],
  providers: [],
  genres: [],
  step: null,
  matchs: []
}

const getters = {
  getCode(state) { return state.code },
  getUsers(state) { return state.users },
  getLikes(state) { return state.likes },
  getDislikes(state) { return state.dislikes },
  getProviders(state) { return state.providers },
  getGenres(state) { return state.genres },
  getStep(state) { return state.step },
  getMatchs(state) { return state.matchs },
}

const mutations = {

  INIT(state) {

    if(localStorage.getItem('partyCode')){
      state.code = localStorage.getItem('partyCode')
    }
  },

  RESET(state) {

    state.code = null
    state.users = []
    state.likes = []
    state.dislikes = []
    state.providers = []
    state.genres = []
    state.step = null
    state.matchs = []
  },

  SET_CODE(state, code){

    state.code = code
    localStorage.setItem('partyCode', code)
  },

  REMOVE_CODE(state){

    state.code = null
    localStorage.removeItem('partyCode')
  },

  SET_USERS(state, users){
    state.users = users
  },

  SET_PROVIDERS(state, providers){
    state.providers = providers
  },

  SET_GENRES(state, genres){
    state.genres = genres
  },

  SET_STEP(state, step){
    state.step = step
  },

  SET_MATCHS(state, matchs){
    state.matchs = matchs
  }
}

const actions = {
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
