<script src="https://unpkg.com/vue/dist/vue.js"></script>
<script src="https://unpkg.com/vue-router/dist/vue-router.js"></script>

<template>
  <v-app>

    <app-header 
      :showDisconnect="getPartyCode() != null" 
      :isConnectedWithImdb="isConnected()"
      @onDisconnect="disconnect"
      @onSendRequest="(v) => sendRequest(v)"
      @onLinkImdb="onImdbLogin"
      @onUnlinkImdb="onImdbLogout"/>

    <v-main transition="slide-x-transition">

      <router-view />

      <v-overlay :value="loading != 0" z-index="998">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>

      <v-dialog v-model="dialog">
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialog = false">Ok</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      top
      >
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Ok
          </v-btn>
        </template>
      </v-snackbar>

    </v-main>
  </v-app>
</template>

<script>
  import AppHeader from '@/components/Header.vue'
  import AppFooter from '@/components/Footer.vue'

  import { mapGetters, mapMutations } from 'vuex'

  export default {

    components: {
      AppHeader,
      AppFooter
    },

    data: () => ({
      dialog: false,
      dialogText: null,
      dialogTitle: null,

      snackbar: false,
      snackbarText: '',
      snackbarTimeout: 2000,

      loading: 0
    }),

    mounted() {

      this.$socket.emit('getAllGenres')
      this.$socket.emit('getAllProviders')

      // Check for existing session
      if(sessionStorage.getItem('sessionId')){
        this.$socket.emit('updateImdbSession', sessionStorage.getItem('sessionId'))
      }
      else{

        // Check imdb login
        var request_token = new URL(location.href).searchParams.get('request_token')
        var approved = new URL(location.href).searchParams.get('approved')
  
        if(!this.isConnected() && approved === 'true' && request_token != null){
          console.log(approved, request_token)
          this.$socket.emit('newImdbSession', request_token)
        }
        // else if(this.getSession() != null){
        //   this.$store.dispatch("imdb/getAccountDetails", {sessionId: this.getSession().session_id}).then( (accountDetails) => {
        //     this.$socket.emit('createOrUpdateUserAccount', accountDetails )
        //   })
        // }
      }

      // check address before local storage
      if(this.$route.params.partyCode && localStorage.getItem('name')){
        console.log("this.$route.params.partyCode", this.$route.params.partyCode)
        this.$socket.emit("joinParty", {partyCode: this.$route.params.partyCode, username: localStorage.getItem('name')})
      }

      // check local storage
      else if(localStorage.getItem('partyCode') && localStorage.getItem('name')){
        console.log("localStorage.getItem('partyCode')", localStorage.getItem('partyCode'))
        let partyCode = localStorage.getItem('partyCode')
        this.$socket.emit("joinParty", {partyCode: partyCode, username: localStorage.getItem('name')})
      }
    },

    sockets: {

      joinParty({code, id, name, step, users, movies, genres, providers}) {
        console.log("Party joined: ", code, movies)
        
        // store update
        this.resetParty()
        this.resetMovies()

        this.setPartyCode(code)
        this.setUserName(name)
        this.setUserId(id)
        this.setStep(step)
        this.setPartyUsers(users)
        this.setPartyProviders(providers)
        this.setPartyGenres(genres)
        movies.forEach(m => this.addPartyMovie(m))

        // go next
        if(this.$route.fullPath != `/${code}`){
          console.log(`this.$route.fullPath != /${code}`)
          this.$router.push(`/${code}`)
        }
      },

      partyNotFound(partyCode) {
        console.log("Party not found: ", partyCode)

        // store update
        this.removePartyCode(null)

        this.$router.push('/')
        this.dialogTitle = 'Party not found'
        this.dialogText = `The party with code ${partyCode} cannot be found.`
        this.dialog = true
      },

      usersInParty(users) {
        console.log("Users in party: ", users)

        // store update
        this.setPartyUsers(users)
      },

      providers(providers){
        console.log("Providers: ", providers)

      // store update
      this.setProviders(providers)

      },

      providersVotes(providers) {
        console.log("Providers voted: ", providers)

        // store update
        this.setPartyProviders(providers)
      },

      genresVotes(genres) {
        console.log("Genres voted: ", genres)

        // store update
        this.setPartyGenres(genres)
      },

      genres(genres){
        console.log("Genres: ", genres)

        // store update
        this.setGenres(genres)
      },

      updateStep(step) {
        console.log("updateStep", step)
        
        // store update
        this.setStep(step)
      },

      movies(movies) {
        console.log("movies", movies)
        
        // store update
        this.setMovies(movies)
      },

      addMovie(movie) {
        console.log("addMovie", movie)

        this.loading ++
        setTimeout(() => {this.loading --}, 2000);
        
        // store update
        this.addPartyMovie(movie)
      },

      match(movie) {
        console.log("match", movie)

        // this.snackbarText = `Nouveau match: ${movie.title} !`
        // this.snackbar = true
      },

      disconnect() {
        console.log("disconnected from server")
        this.$router.push(`/`)
        this.removePartyCode()
      },

      nameAlreadyTaken(name){
        console.log("Name already taken")

        this.$socket.emit('quitParty')
        this.$router.push(`/`)
        this.removePartyCode()

        this.dialogTitle = 'Name already exists'
        this.dialogText = `The user ${name} is already connected.`
        this.dialog = true
      },

      imdbAccount(user){
        console.log("imdbAccount", user)
        this.setImdbUser(user)
      },

      requestSuccess(){

        this.snackbarText = "Requête envoyé."
        this.snackbar = true
      },

      authToken(token){
        console.log("authToken", token)
        window.open(`https://www.themoviedb.org/authenticate/${token}?redirect_to=${location.href}`).focus()
      },

      error(error){
        this.snackbarText = error
        this.snackbar = true
      }
    },

    methods: {

      ...mapMutations({ 
        setPartyCode: 'party/SET_CODE', 
        removePartyCode: 'party/REMOVE_CODE',
        setPartyUsers: 'party/SET_USERS',
        setPartyProviders: 'party/SET_PROVIDERS',
        setProviders: 'providers/SET_PROVIDERS',
        setPartyGenres: 'party/SET_GENRES',
        setGenres: 'movies/SET_GENRES',
        setUserId: 'user/SET_ID',
        setStep: 'party/SET_STEP',
        setUserName: 'user/SET_NAME',
        setMovies: 'movies/SET_MOVIES',
        resetParty: 'party/RESET',
        addPartyMovie: 'movies/ADD_MOVIE',
        resetMovies: 'movies/RESET',
        setMatchs: 'party/SET_MATCHS',
        setImdbUser: 'user/SET_IMDB_USER',
        unsetImdbUser: 'user/UNSET_IMDB_USER',
      }),

      ...mapGetters({ 
        getPartyCode: 'party/getCode',
        getMovies: 'movies/getMovies',
        isConnected: 'user/isConnected'
      }),

      disconnect() {
        this.$socket.emit('quitParty')
        this.$router.push(`/`)
        this.removePartyCode()
      },

      sendRequest(request) {

        console.log("sendRequest", request)
        this.$socket.emit("request", request)
      },

      onImdbLogin() {

        this.$socket.emit("getAuthToken")
      },

      onImdbLogout() {

        this.unsetImdbUser()
      }
    }
  }
  </script>

<style></style>
