import users from '@/api/moviematcher/users'

const state = {
  user: {
    _id: null,
    name: null,
    imdbId: null,
    likes: [],
    dislikes: [],
    seen: [],
    preferences: {
      dateStart: null,
      dateEnd: null,
      alreadySeen: false,
      genres: [],
      providers: []
    }
  }
}

const getters = {
  getUsername(state) { return state.user.name },
  getLikes(state) { return state.user.likes },
  getDislikes(state) { return state.user.dislikes },
  getPreferences(state) { return state.user.preferences },
  getSeen(state) { return state.user.seen },
  getId(state) { return state.user._id },
  isConnected(state) { return state.user.imdbId != null }
}

const mutations = {

  SET_USER(state, user){
    state.user = user
  },

  SET_USERNAME(state, username){
    state.name = username
  },

  SET_ID(state, id){
    state.user._id = id
  },

  SET_NAME(state, name){
    state.user.name = name
    localStorage.setItem('name', name)
  },

  SET_PREFERENCES(state, preferences){
    state.user.preferences = preferences
  },

  LIKE_MOVIE(state, movieId) {
    state.user.likes = [ ...state.user.likes.filter( d => d != movieId), movieId]
    state.user.dislikes = state.user.dislikes.filter( d => d != movieId)
  },

  DISLIKE_MOVIE(state, movieId) {
    state.user.dislikes = [ ...state.user.dislikes.filter( d => d != movieId), movieId]
    state.user.likes = state.user.likes.filter( d => d != movieId)
  },

  SEEN_MOVIE(state, movieId) {
    state.user.seen = [ ...state.user.seen.filter( d => d != movieId), movieId]
  },

  NOT_SEEN_MOVIE(state, movieId) {
    state.user.seen = [ ...state.user.seen.filter( d => d != movieId)]
  },

  SET_IMDB_USER(state, user){

    state.user.imdbId = user.id
    state.user.name = user.username

    sessionStorage.setItem("sessionId", user.sessionId)
  },

  UNSET_IMDB_USER(state){
    state.user.imdbId = null
    state.user.name = null

    sessionStorage.removeItem("sessionId")
  },
}

const actions = {

  async createOrUpdateUser({commit}, {imdbAccount}) {

    try {

      await users.getByImdbId(imdbAccount.id).then( async result => {

        const updateResult =  await users.update({id: result.data.result._id, body: {name: imdbAccount.username, imdbId: imdbAccount.id }})
        commit("SET_USER", updateResult.data.result)

      }).catch(async error => {

        if(error.response.request.status == 404){

          const createResult =  await users.create({name: imdbAccount.username, imdbId: imdbAccount.id, preferences: { dateStart: Date.now(), dateEnd: Date.now() } })
          commit("SET_USER", createResult.data.result)
        }
        else{

          throw error
        }
      })
    }
    catch( error ){
      return error
    }
  },

  async like({commit, state}, {movieId}) {
    await users.like({userId: state.user._id, movieId: movieId})
    commit("LIKE_MOVIE", movieId)
  },

  async dislike({commit, state}, {movieId}) {
    await users.dislike({userId: state.user._id, movieId: movieId})
    commit("DISLIKE_MOVIE", movieId)
  },

  async seen({commit, state}, {movieId}) {
    await users.seen({userId: state.user._id, movieId: movieId})
    commit("SEEN_MOVIE", movieId)
  },

  async notSeen({commit, state}, {movieId}) {
    await users.notSeen({userId: state.user._id, movieId: movieId})
    commit("NOT_SEEN_MOVIE", movieId)
  },

  async updatePreferences({commit, state}, {preferences}) {
    await users.updatePreferences({userId: state.user._id, preferences: preferences})
    commit("SET_PREFERENCES", preferences)
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
