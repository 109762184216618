<template>
  <v-layout justify-center fill-height align-center column>

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" top>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="onSnackbar()">
          Voir
        </v-btn>
      </template>
    </v-snackbar>

    <v-tabs-items v-model="tab" touchless style="height: inherit; width: 100%; padding-bottom: 72px;">
      <v-tab-item eager value="tab-1" style="height: inherit; width: 100%;">
        <v-layout justify-center fill-height align-center column>
          <v-container flex class="body">
              <div class="movies" id="movies">
                <MovieCard 
                  v-for="movie in movies" 
                  :key="movie.id" 
                  :movie="movie"
                />
              </div>
              <div v-if="movies.length > 0" class="buttons mt-5">
                <v-btn outlined color="yellow" fab elevation="2" small class="ma-3" @click="onUndo()" :disabled="last == null">
                  <v-icon color="yellow" >mdi-undo</v-icon>
                </v-btn>
                <v-btn outlined color="red" fab elevation="2" class="ma-3" @click="onDislike(frontMovie.id)">
                  <v-icon large color="red" >mdi-close</v-icon>
                </v-btn>
                <v-btn outlined color="blue" fab elevation="2" small class="ma-3">
                  <v-icon color="blue" >mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn outlined color="green" fab elevation="2" class="ma-3" @click="onLike(frontMovie.id)">
                  <v-icon large color="green">mdi-cards-heart-outline</v-icon>
                </v-btn>
                <v-btn outlined color="grey" fab elevation="2" small class="ma-3" :href='"https://www.themoviedb.org/movie/"+frontMovie.id' target="_blank">
                  <v-icon color="grey" >mdi-information-outline</v-icon>
                </v-btn>
              </div>
          </v-container>
        </v-layout>
      </v-tab-item>
    
      <v-tab-item eager value="tab-2" style="height: inherit; width: 100%;">
        <v-layout fill-height style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start;">
          <v-card
            v-for="movie in matchs"
            :key="movie.id"
            style="width: 45%; height: fit-content;"
            class="ma-2"
          >
          <a :href='"https://www.themoviedb.org/movie/"+movie.id' target="_blank">
            <v-img :src="'https://image.tmdb.org/t/p/w185'+movie.poster_path"/>
          </a>

          </v-card>
        </v-layout>
      </v-tab-item>
    
      <v-tab-item eager value="tab-3" style="height: inherit; width: 100%;">
        <v-layout justify-center fill-height align-center column>
          Filtres
        </v-layout>
      </v-tab-item>
    </v-tabs-items> 
    
    <v-tabs
      v-model="tab"
      centered
      icons-and-text
      style="position: fixed; bottom: 0px;"
      grow
    >
  
      <v-tabs-slider></v-tabs-slider>
  
      <v-tab href="#tab-1">
        Films
        <v-icon>mdi-television</v-icon>
      </v-tab>
  
      <v-tab href="#tab-2">
        Matchs
        <v-badge
          color="blue"
        >
          <v-icon>mdi-heart</v-icon>
        </v-badge>
      </v-tab>
  
      <v-tab href="#tab-3">
        Filtres
        <v-icon>mdi-tune-vertical</v-icon>
      </v-tab>
  
    </v-tabs>
  </v-layout>
</template>

<script>

  import * as Hammer from "hammerjs"
  import MovieCard from "./MovieCard.vue"

  export default {
    name: "MovieMatcher",

    components: { MovieCard },

    data: () => ({
        smooth: 0.3,
        maxAngle: 42,
        threshold: 42,
        thresholdMatch: 150,
        pageNumber: 1,

        tab: 1,
        last: null,

        snackbar: false,
        snackbarText: "",
        snackbarTimeout: 2000
    }),
    
    props: {
      movies: { type: Array, default: () => []},
      matchs: { type: Array, default: () => []},
    },

    mounted(){

      // Select the node that will be observed for mutations
      const targetNode = document.getElementById('movies')

      targetNode.childNodes.forEach(this.setupDragAndDrop)

      // Options for the observer (which mutations to observe)
      const config = { childList: true};

      // Callback function to execute when mutations are observed
      const callback = (mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(element => {
              console.log("movie added", element)
              this.setupDragAndDrop(element)
            });
          }
        }
      };

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback);

      // Start observing the target node for configured mutations
      observer.observe(targetNode, config);
    },

    sockets: {

      match(movieId){
        console.log("match", movieId)
        this.snackbarText = `Nouveau match pour ${this.getMovies().find(m => m.id == movieId).title} !`
        this.snackbar = true
      }
    },

    computed: {

      frontMovie(){
        return this.movies[this.movies.length-1]
      }
    },
    
    methods: {

      setupDragAndDrop(movie) {

          const hammertime = new Hammer(movie);

          hammertime.on("pan", e => {
              movie.classList.remove("movie--back");
              movie.classList.remove("movie--matching");
              movie.classList.remove("movie--nexting");
              let posX = e.deltaX;
              let posY = Math.max(0, Math.abs(posX * this.smooth) - this.threshold);
              let angle = Math.min(Math.abs(e.deltaX * this.smooth / 100), 1) * this.maxAngle;
              if (e.deltaX < 0)
                  angle *= -1;
              movie.style.transform = `translateX(${posX}px) translateY(${posY}px) rotate(${angle}deg)`;
              if (posX > this.thresholdMatch) {
                  movie.classList.remove("movie--nexting");
                  movie.classList.add("movie--matching");
              }
              else if (posX < -this.thresholdMatch) {
                  movie.classList.add("movie--nexting");
                  movie.classList.remove("movie--matching");
              }

              if (e.isFinal) {
                  movie.style.transform = "";
                  if (posX > this.thresholdMatch){
                    this.onLike(this.frontMovie.id)
                    movie.classList.add("movie--match");
                  }
                  else if (posX < -this.thresholdMatch){
                    this.onDislike(this.frontMovie.id)
                    movie.classList.add("movie--next");
                  }
                  else
                      movie.classList.add("movie--back");
              }
          });
      },

      onLike(movieId) {
        this.last = movieId
        this.$emit("onLike", movieId)
      },

      onDislike(movieId){
        this.last = movieId
        this.$emit('onDislike', movieId)
      },

      onUndo(){
        this.$emit('onUnvote', this.last)
      },

      onSnackbar(){
        this.snackbar = false
        this.tab = 2
      }
    }
}
</script>

<style lang="scss">

:root {
  --light-grey:#ececec;
  --dark-grey:#acacac;
  --green:#4CAF50;
  --red:#FF5252;
  --black-transparent:rgba(0,0,0, 0.8);

  --transition-time:.4s;
}

* {
  box-sizing: border-box;
  margin:0;
  padding: 0;
}

html {
  background:var(--light-grey);
}

.v-main__wrap {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.movies {
  max-height: 450px;
  max-width: 300px;
  position: relative;
  margin: auto;
  height: 100%;
}

.container {
  padding: 8px;
  height: 100%;
}

.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>