import providers from '@/api/imdb/providers'

const state = {
  providers: [],
  movieProviders: []
}

const getters = {
  getProviders(state) { return state.providers },
  getMovieProviders(state) { return state.movieProviders }
}

const mutations = {

  SET_PROVIDERS(state, providers){
    state.providers = providers
  },

  SET_MOVIE_PROVIDERS(state, {movieId, providers}){
    state.movieProviders.push({id: movieId, providers})
  }
}

const actions = {

  async getByMovieId({commit}, {movieId}) {
    try {
      const result = await providers.getByMovieId(movieId)
      commit("SET_MOVIE_PROVIDERS", { movieId: movieId, providers: result.data.results['FR']})
      return result.data.results['FR']
    }
    catch( error ){
      return error
    }
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
