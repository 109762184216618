<template>
  <v-layout justify-center align-center column pa-5>
    <v-btn fab dark absolute fixed bottom right class="mb-10" @click="onGenresReady()"><v-icon>{{ fabIcon }}</v-icon></v-btn>
    Selection des genres:
    <v-text-field v-model="genresFilter" prepend-icon="mdi-magnify"></v-text-field>
    <v-list dense flat>
      <v-list-item-group v-model="genresVoted" multiple>
        <v-list-item v-for="genre, i in filteredGenres" :key="genre.id" @click="toggleGenre(genre.id)">
          <v-list-item-avatar style="overflow: visible;">
            <v-chip>{{ getVoteCount(genre.id) }}</v-chip>
            <v-badge v-show="genresVoted.includes(i)" bordered bottom dot offset-x="8" offset-y="-5" style="z-index: 40;" ></v-badge>
          </v-list-item-avatar>
          <v-list-item-content>{{ genre.name }}</v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-layout>
</template>

<script>
  export default {
    name: 'GenreSelection',

    data: () => ({
      genresVoted: [],
      genresFilter: ""
    }),

    props: {
      genres: { type: Array, default: () => []},
      partyGenres: { type: Array, default: () => []},
      users: { type: Array, default: () => []},
      userId: { type: String, required: true}
    },

    computed: {

      fabIcon(){
        return this.users.find(u => u.id == this.userId).genresReady ? "mdi-close" : "mdi-check"
      },

      filteredGenres() {

        const filter = this.genresFilter.toUpperCase()

        return this.genres.filter( p => {
          const name = p.name.toUpperCase()
          return name.includes(filter) || filter.includes(name)
        })
      },
    },

    methods: {

      getVoteCount(id){

        if(this.partyGenres.find(p => p.id == id)){
          return this.partyGenres.find(p => p.id == id).voteCount
        }
        else{
          return 0
        }
      },

      toggleGenre(id){

        if(!this.genresVoted.includes(this.genres.indexOf(this.genres.find(p => p.id == id)))){
          this.$emit('onVoteGenre', id)
        }
        else{
          this.$emit('onUnvoteGenre', id)
        }
      },

      onGenresReady(){
        this.$emit("onGenresReady")
      }
    }
  }
</script>