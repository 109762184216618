import axios from 'axios'

export default {

  async getById(userId) {
    return axios.get(`http://localhost:5001/v1/users/${userId}`)
  },

  async getLikes(userId) {
    return axios.get(`http://localhost:5001/v1/users/${userId}/likes`)
  },

  async getDislikes(userId) {
    return axios.get(`http://localhost:5001/v1/users/${userId}/dislikes`)
  },

  async getByImdbId(imdbId) {
    return axios.get(`http://localhost:5001/v1/users/imdb/${imdbId}`)
  },

  async create(body) {
    return axios.post(`http://localhost:5001/v1/users`, { ...body })
  },

  async update({id, body}) {
    return axios.put(`http://localhost:5001/v1/users/${id}`, { ...body })
  },

  async like({userId, movieId}) {
    return axios.put(`http://localhost:5001/v1/users/${userId}/like/${movieId}`)
  },

  async dislike({userId, movieId}) {
    return axios.put(`http://localhost:5001/v1/users/${userId}/dislike/${movieId}`)
  },

  async seen({userId, movieId}) {
    return axios.put(`http://localhost:5001/v1/users/${userId}/seen/${movieId}`)
  },

  async notSeen({userId, movieId}) {
    return axios.put(`http://localhost:5001/v1/users/${userId}/notseen/${movieId}`)
  },

  async updatePreferences({userId, preferences}) {
    return axios.put(`http://localhost:5001/v1/users/${userId}/preferences`, {preferences})
  },
}