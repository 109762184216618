import movies from '@/api/imdb/movies'

const state = {
  movies: [],
  genres: []
}

const getters = {
  getMovies(state) { return state.movies },
  getGenres(state) { return state.genres },
  getMatchs(state) { return state.movies.filter(m => m.match) }
}

const mutations = {

  SET_MOVIES(state, movies){
    state.movies = movies
  },

  ADD_MOVIE(state, movie){
    state.movies.unshift(movie)
  },

  RESET(state){
    state.movies = []
  },

  SET_GENRES(state, genres){
    state.genres = genres
  },

  SET_MOVIE(state, movie){
    state.movies = [ ...state.movies.filter( m => m.id != movie.id), movie ]
  },
}

const actions = {

  async discover({commit}, {pageNumber, preferences, seen}) {
    try {
      let result = await movies.discover({pageNumber, preferences})

      if(!preferences.alreadySeen){
        const movies = result.data.results.filter(r => seen.find(s => s == r.id) == null)
        commit("SET_MOVIES", movies)
        return movies
      }
      else{
        commit("SET_MOVIES", result.data.results)
        return result.data.results
      }
    }
    catch( error ){
      return error
    }
  },

  async getById({commit}, {movieId}) {
    try {
      const result = await movies.getById(movieId)
      commit("SET_MOVIE", result.data.result)
    }
    catch( error ){
      return error
    }
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
