import authentication from '@/api/imdb/authentication'
import sessions from '@/api/imdb/sessions'
import account from '@/api/imdb/account'

const state = {
  token: null,
  session: null,
  account: null
}

const getters = {
  getSession(state) { return state.session },
  getAccount(state) { return state.account },
  isLoggedIn(state) { return state.session != null }
}

const mutations = {

  INIT(state) {

    console.log("initialise imdb session start")

    if (localStorage.getItem('session_id')) {
      state.session = {session_id: localStorage.getItem('session_id')}
      console.log("state.session = ", state.session)
    }

    console.log("initialise imdb session end")
  },

  SET_TOKEN(state, token){
    state.token = token
  },

  SET_SESSION(state, session) {
    state.session = session
    localStorage.setItem('session_id', state.session.session_id);
  },

  REMOVE_SESSION(state) {
    state.session = null
    localStorage.removeItem('session_id');
  },

  SET_ACCOUNT(state, account){
    state.account = account
  }
}

const actions = {

  async getNewAuthenticationToken({commit}){
    try {
      const result = await authentication.getToken()
      commit("SET_TOKEN", result.request_token)
      return result
    } catch (error) {
      return error
    }
  },

  async createNewSession({commit}, {token}){
    try {
      const result = await sessions.create(token)
      commit('SET_SESSION', result.data)
      return result
    } catch (error) {
      return error
    }
  },

  async getAccountDetails({commit}, {sessionId}){
    try {
      const result = await account.getDetails(sessionId)
      commit('SET_ACCOUNT', result.data)
      return result.data
    } catch (error) {
      return error
    }
  },

  async disconnect({commit}, {sessionId}){
    try {
      await sessions.delete(sessionId)
      commit('REMOVE_SESSION')
    } catch (error) {
      return error
    }
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
