<template>
  <v-container fluid>
    <waiting-room 
      v-if="getStep()=='waiting'" 
      :users="getPartyUsers()" 
      :userId="getUserId()" 
      :partyCode="getPartyCode()"
      @onReady="toggleReady()"
      />
    <provider-selection 
      v-else-if="getStep()=='providers'"
      :providers="getProviders()"
      :partyProviders="getPartyProviders()"
      :users="getPartyUsers()"
      :userId="getUserId()"
      @onProvidersReady="toggleProvidersReady()" 
      @onVoteProvider="(id) => addProviderVote(id)"
      @onUnvoteProvider="(id) => removeProviderVote(id)"
      />
    <genre-selection
      v-else-if="getStep()=='genres'"
      :genres="getGenres()"
      :partyGenres="getPartyGenres()"
      :users="getPartyUsers()"
      :userId="getUserId()"
      @onGenresReady="toggleGenresReady()" 
      @onVoteGenre="(id) => addGenreVote(id)"
      @onUnvoteGenre="(id) => removeGenreVote(id)"
      />
    <movie-matcher
      v-else-if="getStep()=='movies'"
      :movies="movies"
      :matchs="getMatchs()"
      @onLike="(id) => likeMovie(id)"
      @onDislike="(id) => dislikeMovie(id)"
      @onUnvote="(id) => unvoteMovie(id)"
      />
  </v-container>
</template>

<script>
import MovieMatcher from '@/components/MovieMatcher.vue';
import { mapGetters } from 'vuex';
import WaitingRoom from '@/components/WaitingRoom.vue';
import ProviderSelection from '@/components/ProviderSelection.vue';
import GenreSelection from '@/components/GenreSelection.vue';


export default {
  name: 'Party',

  components: { MovieMatcher, WaitingRoom, ProviderSelection, GenreSelection },

  computed: {

    movies() {
      let results = this.getMovies()
      .filter(m => 
        !(m.likes && m.likes.find(l => l == this.getUserId())) && 
        !(m.dislikes && m.dislikes.find(l => l == this.getUserId()))
      )

      console.log("movies:", results.length)

      return results
    },

    url(){ return window.location.href}
  },

  methods: {

    ...mapGetters({
      getPartyUsers: 'party/getUsers',
      getPartyProviders: 'party/getProviders',
      getPartyGenres: 'party/getGenres',
      getProviders: 'providers/getProviders',
      getGenres: 'movies/getGenres',
      getMovies: 'movies/getMovies',
      getUserId: 'user/getId',
      getStep: 'party/getStep',
      getMatchs: 'movies/getMatchs',
      getPartyCode: 'party/getCode'
    }),

    toggleReady(){
      this.$socket.emit('toggleReady')
    },

    toggleProvidersReady(){
      this.$socket.emit('toggleProvidersReady')
    },

    addProviderVote(id){
      this.$socket.emit('addProviderVote', id)
    },

    removeProviderVote(id){
      this.$socket.emit('removeProviderVote', id)
    },

    toggleGenresReady(){
      this.$socket.emit('toggleGenresReady')
    },

    addGenreVote(id){
      this.$socket.emit('addGenreVote', id)
    },

    removeGenreVote(id){
      this.$socket.emit('removeGenreVote', id)
    },

    likeMovie(id){
      this.$socket.emit('like', id)
    },

    dislikeMovie(id){
      this.$socket.emit('dislike', id)
    },

    unvoteMovie(id){
      this.$socket.emit('unvote', id)
    }
  }
}
</script>

<style lang="scss">

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>