<template>
  <div class="movie">
    <v-img class="movie__image" :src="'https://image.tmdb.org/t/p/w300'+movie.poster_path"></v-img>
    <div class="movie__infos">
      <v-row>
        <v-img class="provider--logo" v-for="provider in getProvidersFlatrate()" :key="provider.logo_path" :src="`https://www.themoviedb.org/t/p/original/${provider.logo_path}`"></v-img>
        <v-spacer></v-spacer>
        <v-icon class="seen--logo" color="white" v-show="seen">mdi-eye</v-icon>
      </v-row>
      <div class="movie__title">
        {{ movie.title }}
      </div>
      <div>
        <span class="movie__release">{{ movie.release_date }}</span>
      </div>
      <div class="movie__overview">{{ movie.overview.substring(0, 100) }}...</div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "MovieCard",

    data: () => ({

    }),

    props: {
      movie: { type: Object, required: true },
      seen: { type: Boolean, default: false }
    },

    methods: {

      getProvidersFlatrate(){

        if(this.movie.providers && this.movie.providers.flatrate){
          return this.movie.providers.flatrate
        }
        else{
          return null
        }
      }
    }
  }

</script>


<style lang="scss">

img {
  max-width: 100%;
}

.movie {
  position: absolute;
  left:0px;
  top:0px;
  width:100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  cursor:pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.movies::before {
  content:'Plus rien à matcher...';
  color:var(--dark-grey);
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
}

.movie__image {
  height: 0;
  padding-bottom:150%;
  background-size: cover;
  background-position: center center;
}

.movie__infos {
  padding:12px;
  position:absolute;
  bottom:0px;
  background:linear-gradient(0deg, black, transparent);
  width: 100%;
}

.movie__title {
  color:white;
  font-weight: bold;
  font-size:22px;
}

.movie__release {
  color:var(--light-grey);
  font-weight: normal;
}

.movie__overview {
  color:white;
  font-size:13px;
}

.movie--match {
  transition: var(--transition-time);
  transform:translateX(1500px) translateY(600px) rotate(90deg);
}

.movie--next {
  transition: var(--transition-time);
  transform:translateX(-1500px) translateY(600px) rotate(-90deg);
}

.movie--back {
  transition:var(--transition-time);
}

.movie--matching::after, .movie--nexting::after {
  content:'MATCH';
  font-weight: bold;
  font-size:28px;
  padding:8px 12px;
  border:solid 2px var(--green);
  color:var(--green);
  background:var(--black-transparent);
  position:absolute;
  top:24px;
  left:12px;
  border-radius: 4px;
  transform:rotate(-24deg);
} 

.movie--nexting::after {
  content:'NEXT';
  border:solid 2px var(--red);
  color:var(--red);
  right:12px;
  left: auto;
  transform:rotate(24deg);
}

.provider--logo {

  max-width: 35px;
  margin-left: 12px;
  margin-bottom: 16px;
}

.seen--logo {

  max-width: 35px;
  margin-left: 12px;
  margin-bottom: 16px;
  margin-right: 12px;
}

</style>