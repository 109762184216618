<template>
  <div>
    <v-app-bar app>

      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>
      <v-toolbar-title>Movie Matcher</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon disabled></v-btn>

    </v-app-bar>
    
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      >

      <v-list dense nav>
      
        <v-list-item @click="onUnlinkImdb" v-if="!showDisconnect && isConnectedWithImdb">
          <v-list-item-icon>
            <v-icon>mdi-filmstrip</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Se déconnecter de Imdb</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="onLinkImdb" v-if="!showDisconnect && !isConnectedWithImdb">
          <v-list-item-icon>
            <v-icon>mdi-filmstrip</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Se connecter via IMDB</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-dialog v-if="showDisconnect" v-model="dialogDisconnect">

          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-on="on" v-bind="attrs">
              <v-list-item-icon>
                <v-icon>mdi-close</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Se déconecter</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-card>
            <v-card-title>Quitter la session en cours</v-card-title>
            <v-card-text>Voulez-vous quitter la session en cours ?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="red" @click="dialogDisconnect = false">Annuler</v-btn>
              <v-btn text @click="onDisconnect">Oui</v-btn>
            </v-card-actions>
          </v-card>

          </v-dialog>
      
      </v-list>

      <v-list dense nav style="bottom: 0px; position: absolute;">

        <v-list-item @click="onOpenRequestDialog">
          <v-list-item-icon>
            <v-icon>mdi-bug</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Envoyer une requête</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link href="https://www.paypal.com/paypalme/benjamindb" target="_blank">
          <v-list-item-icon>
            <v-icon>mdi-hand-coin</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Faire un don</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    
    </v-navigation-drawer>

    <v-dialog v-model="dialogBug">
      <v-card>
        <v-card-title>Envoyer une requête</v-card-title>
        <v-card-text>
          <v-form ref="formRequest">
            <v-text-field label="Titre" v-model="request.title" required :rules="titleRules"></v-text-field>
            <v-textarea label="Description" v-model="request.description" required :rules="descriptionRules"></v-textarea>
            Catégorie:
            <v-radio-group v-model="request.category" mandatory>
              <v-radio v-for="categorie in categories" :key="categorie.value" :label="categorie.label" :value="categorie.value"></v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialogBug=false">Annuler</v-btn>
          <v-btn text color="primary" @click="onSendRequest">Envoyer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

  export default {
    name: 'Header',

    data: () => ({
      drawer: false,
      dialogDisconnect: false,
      dialogBug: false,

      categories: [
        {value: 0, label: "Bug"},
        {value: 1, label: "Amélioration"},
        {value: 2, label: "Autre"}
      ],

      titleRules: [
        v => !!v || 'Le titre est requis.'
      ],
      descriptionRules: [
        v => !!v || 'La description est requise.'
      ],

      request: {
        title: '',
        description: '',
        category: null
      }
    }),

    props: {
      showDisconnect: { type: Boolean, required: true },
      isConnectedWithImdb: { type: Boolean, default: false }
    },

    methods: {

      onDisconnect(){
        this.dialogDisconnect = false
        this.$emit('onDisconnect')
      },

      onOpenRequestDialog(){
        this.drawer = false
        this.dialogBug = true
      },

      onSendRequest(){
        if(this.$refs.formRequest.validate()){
          this.$emit('onSendRequest', this.request)
          this.$refs.formRequest.resetValidation()
          this.$refs.formRequest.reset()
          this.dialogBug = false
        }
      },

      onLinkImdb(){
        this.$emit('onLinkImdb')
      },

      onUnlinkImdb(){
        this.$emit('onUnlinkImdb')
      },
    }
}

</script>