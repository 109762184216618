<template>
  <footer>
    <p style="text-align: center;">Copyright {{ year }} </p>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',

    computed: {
      year() {
        return new Date().getFullYear()
      }
    }
  }
</script>
