import axios from 'axios'

export default {

  async create(token) {
    return axios.post('https://api.themoviedb.org/3/authentication/session/new?api_key=8b7fb4aa32636d1c57093351f7cdb242', {
      request_token: token
    })
  },

  async delete(sessionId) {
    return axios.delete('https://api.themoviedb.org/3/authentication/session?api_key=8b7fb4aa32636d1c57093351f7cdb242', { data: {
      session_id: sessionId
    }})
  }
}