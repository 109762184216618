<template>
  <v-container fluid fill-height>
    
    <v-layout justify-center align-center column pa-5>
      <p v-if="isConnected()">Connecté en tant que {{ getUsername() }}.</p>
      <v-btn @click="openNewDialog" outlined class="ma-2" width="75%">Nouveau salon</v-btn>
      <v-btn @click="openJoinDialog" outlined  width="75%">Rejoindre un salon</v-btn>
    </v-layout>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>{{ dialogTitle }}</v-card-title>
        <v-card-text style="padding-bottom: 0px; padding-top: 24px;">
          <v-form ref="formName">
            <v-text-field v-show="!isConnected()" outlined v-model="pseudo" label="Ton pseudo" required :rules="nameRules"></v-text-field>
          </v-form>
          <v-form ref="formJoin" v-if="join">
            <v-text-field outlined v-model="partyCode" label="Le code du salon à rejoindre" required :rules="codeRules"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="dialog = false">Annuler</v-btn>
          <v-btn text color="primary" @click="onJoin" v-if="join">Rejoindre</v-btn>
          <v-btn text color="primary" @click="onNew" v-else>Créer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

  import { mapGetters } from 'vuex';

  export default {
    name: 'Home',

    data: () =>({
      pseudo: null,
      partyCode: null,

      nameRules: [
        v => !!v || 'Le nom est requis.'
      ],
      codeRules: [
        v => !!v || 'Le code est requis.'
      ],

      dialog: false,
      join: false,
      dialogTitle: null
    }),

    mounted() {

      if(this.isConnected()){
        this.pseudo = this.getUsername()
      }
    },

    sockets: {

      newPartyReady(partyCode) {
        this.partyCode = partyCode
        this.joinParty()
      }
    },

    methods: {

      ...mapGetters({
        getUsername: 'user/getUsername',
        isConnected: 'user/isConnected'
      }),

      joinParty() {
        this.$socket.emit("joinParty", {partyCode: this.partyCode, username: this.pseudo})
      },

      openJoinDialog() {
        this.join = true
        this.dialogTitle = 'Rejoindre un salon existant'
        this.dialog = true
      },

      openNewDialog() {

        if(this.isConnected()){
          this.$socket.emit("newParty")
          return
        }

        this.join = false
        this.dialogTitle = 'Créer un nouveau salon'
        this.dialog = true
      },

      onNew() {
        if(this.$refs.formName.validate()){
          this.$socket.emit("newParty")
        }
      },

      onJoin() {
        if(this.$refs.formName.validate() && this.$refs.formJoin.validate()){
          this.dialog = false
          this.joinParty()
        }
      }
    }
  }
</script>