import axios from 'axios'

export default {

  async getByMovieId(movieId) {
      return axios.get(`https://api.themoviedb.org/3/movie/${movieId}/watch/providers?api_key=8b7fb4aa32636d1c57093351f7cdb242`)
  },
  
  async getAll() {
      return axios.get('https://api.themoviedb.org/3/watch/providers/movie?api_key=8b7fb4aa32636d1c57093351f7cdb242&language=fr-FR&watch_region=FR')
  }
}